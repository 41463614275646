<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">Nuevo</span>
      <span class="lable4" *ngIf="product.sale">Disponible</span>
    </div>
    <div class="front">
      <a>
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
          class="img-fluid lazy-loading" 
          alt="{{ product.images[0].alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div class=" pt-2">
      <a class="text-center">
        <h4 ><b>{{ product?.title | titlecase }}</b></h4>
      </a>
      <p>{{ product?.description }}</p>
    </div>
   <div class="row">
     <div class="col-12 text-center pt-2">
       <button class="btn btn-primary " (click)="CartModal.openModal(product)">Ver Detalle</button>
     </div>
     <div class="col-12 text-center pt-2">
       <a type="button" class="btn btn-success" href="https://wa.me/51996781001" target="_blank"> <b><i class="fa fa-whatsapp"></i>  Cotizar en whatsApp</b></a>
     </div>
   </div>

  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

