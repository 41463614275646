<!--footer section -->
<footer [class]="class">
	<!--<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>¡CONOCE TODO PRIMERO!</h4>
								<p>No te pierdas nada de RentaDream suscribiéndote a nuestro boletín.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Introduce tu correo electrónico">
							</div>
							<button type="submit" class="btn btn-solid">Suscribir</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>-->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Somos una empresa peruana , ofreciendo el servicio de alquiler de autos, en plazas turísticas y de negocio,
							buscando estar siempre presente donde los clientes nos necesiten. </p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Vehículos</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Autos</a></li>
                                <li><a href="javascript:void(0)">Camionetas</a></li>
                                <li><a href="javascript:void(0)">Mini Van</a></li>
                                <li><a href="javascript:void(0)">Ver más</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Asistencia</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
								<li><i class="fa fa-map-marker"></i><a href="https://wa.me/51996781001" target="_blank">Preguntas frecuentes</a></li>
						        <li><i class="fa fa-phone"></i><a  href="https://wa.me/51996781001" target="_blank">Términos y condiciones</a></li>
						        <li><i class="fa fa-envelope-o"></i><a  href="https://wa.me/51996781001" target="_blank">Contáctanos</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright text-uppercase" aria-hidden="true"></i> {{ today | date:'y'}} Copyright  RENT A DREAM</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
					  <p href="">Diseñado y Desarrollado por iPrime</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->
